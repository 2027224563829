import React from 'react';

const OtherPosts = ((posts:any): JSX.Element=>{
    return(
        <div className='otros-items'>
            <div className="wrap-otros">

              <div className='header-otros'>
                <div className="title">
                  <h2>Otros Artículos</h2>
                </div>
                <div className='action'>
                  <div className="link">
                    <a href="/todos">Ver todo</a>
                  </div>
                </div>
              </div>

              <div className="items">

                {
                  posts.posts && posts.posts.map((node:any, index:any)=>{
                    if(index < 3){
                      return(
                        <div key={node.title} className="item">
                          
                          <div className="header-item">
                            <div className="cat">
                              <p>{node.category}</p>
                            </div>
                            <div className="title">
                              <h2>{node.title}</h2>
                            </div>
                          </div>
  
  
                          <div className="footer-item">
                            <div className="date-autor">
                              <p>{node.date}. {node.author}.</p>
                            </div>
                            <div className="desc">
                              <p>{node.description}</p>
                            </div>
                            <div className="link">
                              <a href={node.slug}>Ver más</a>
                            </div>
                          </div>
  
                        </div>
                      )
                    }
                    return <></>
                  })
                }

              </div>
            </div>
        </div>        
    )
})

export default OtherPosts;
