import * as React from 'react';
import {useState} from 'react';

// import CategoryLayout from '../components/CategoryLayout';
import Featured from '../components/layout/Featured';
import OtherPosts from '../components/layout/OtherPosts';
import RecentPosts from '../components/layout/RecentPosts';
import FlashCards from '../components/FlashCards';
import {
  useDiarioEconomicoPosts,
  useFlashEconomicoPosts,
  useOtrosArticulosPosts,
} from '../operations/queries';
// import getCategoryColor from '../utils/getCategoryColor';
// import '../theme/home.scss';

import "../theme/category.scss"
import useIsClient from '../utils/isClient';

import HeaderLayout from '../components/layout/HeaderLayout';
import SearchIcon from '@material-ui/icons/Search';
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';

import ModalSuscribe from '../components/layout/ModalSuscribre';


export default function Tecnico(): JSX.Element {
  const diarioEconomicoPosts = useDiarioEconomicoPosts();
  const flashEconomicoPosts = useFlashEconomicoPosts();
  const otherPosts = useOtrosArticulosPosts();


  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }

  // console.log(otherPosts)
  const isClient = useIsClient();

  if(isClient){
    return (
      <>
        <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe}/>
        <div className="container">
          <div className='header--section'>
            <h2 className='title--section'>Económico</h2>
            <div className='search--section'>
              <form action="/buscar">
                <input type="text" name='q' />
                <SearchIcon className='icon--search'/>  
              </form>
            </div>
          </div>
          <Featured data={diarioEconomicoPosts[0]}/>
          <FlashCards header="Flash Económico" posts={flashEconomicoPosts} />
          <RecentPosts posts={diarioEconomicoPosts} title="Artículos recientes"/>

          <OtherPosts posts={otherPosts}/>
        
        </div>
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>
      // <CategoryLayout title="Económico" newHeader={true}>
      //   <Featured
      //     header="Diario Económico"
      //     post={diarioEconomicoPosts[0]}
      //     vertical
      //   />
      //   <FlashCards header="Flash Económico" posts={flashEconomicoPosts} />
      //   <RecentPosts header="Artículos Recientes" posts={diarioEconomicoPosts} />
      //   <OtherPosts header="Otros Artículos" posts={otherPosts} />
      // </CategoryLayout>
    );
  }else{
    return(<></>)
  }

}
